import { useRouter } from 'next/router'

import { type SanityPromo } from '@data/sanity/queries/types/site'

import CustomLink from '@components/link'

type PromoBarProps = Pick<SanityPromo, 'text' | 'link' | 'display'>

const PromoBar = ({ text, link, display }: PromoBarProps) => {
  const router = useRouter()

  const type = display?.trim()

  // Check that promo type and text are given and the page matches, if type is homepage
  if (!type || !text || (type === 'home' && router.asPath !== '/')) {
    return null
  }

  return (
    <div className="relative z-60 bg-yellow text-pageText text-center whitespace-nowrap overflow-x-auto overflow-y-hidden">
      <div className="inline-flex px-8 py-2 text-sm font-semibold">
        {link && (
          <CustomLink
            link={{
              page: link,
            }}
            className="block"
          >
            {text}
          </CustomLink>
        )}
        {!link && <>{text}</>}
      </div>
    </div>
  )
}

export default PromoBar
